@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@layer components {
  .button-primary {
    @apply p-2 bg-red-primary hover:bg-red-secondary text-white text-center font-bold rounded-full transition-all disabled:opacity-70;
  }
  .button-secondary {
    @apply p-2 bg-gray-secondary hover:bg-gray-primary text-black hover:text-white text-center rounded-full transition-all disabled:opacity-70;
  }
  .button-third {
    @apply p-2 bg-gray-primary hover:bg-black text-white  text-center rounded-full transition-all disabled:opacity-70;
  }
  .input-base {
    @apply w-full h-[40px] rounded-lg bg-white px-[13px] border-2 border-gray-secondary focus:border-2 focus:border-black flex items-center outline-none disabled:opacity-70;
  }
  .input-error {
    @apply border sm:border-2 border-red-primary text-red-primary focus:border-red-primary;
  }
  .input-valid {
    @apply border sm:border-2 border-green-500 text-green-500 focus:border-green-500;
  }
  .link-without-submenu {
    @apply w-full h-12 px-[14px] mb-[14px] flex justify-between items-center rounded-[8px] border shadow-custom-sm text-gray-primary font-medium;
  }
  .link-without-submenu i::before{
    font-weight: bold !important;
  }
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-100;
}

.react-daterange-picker {
  @apply w-full
}

.react-daterange-picker__wrapper{
  @apply w-full h-[40px] rounded-lg bg-white px-[13px] !border-2 !border-gray-secondary focus:!border-2 focus:!border-black flex items-center outline-none disabled:opacity-70
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-daterange-picker__calendar{
  z-index: 1000 !important;
  background: #fff !important;
  margin-top: 8px;
}

.react-calendar{
  border-radius: 16px !important;
  overflow: hidden !important;
}

.react-daterange-picker__inputGroup{
  justify-content: center !important;
  display: flex;
  align-items: center;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert{
  width: 100%;
}

.react-confirm-alert-body {
  width: 90%;
  max-width: 450px;
  padding: 32px;
  text-align: left;
  background: #fff;
  border-radius: 18px;
  text-align: center;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #767676;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: auto;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  width: 100%;
  font-family: 'Roboto';
  font-style: medium;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 20px;
}

.react-confirm-alert-body > h3 {
  width: 100%;
  font-family: 'Roboto';
  font-style: medium;
  font-weight: 500;
  text-align: center;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 20px;
}

.react-confirm-alert-button-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  margin-top: 20px;
  gap: 10px;
}

.react-confirm-alert-button-group > button {
  width: 45%;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 300ms;
}